

.confirmationPopupPageContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.confirmationPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
}

.confirmationPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    width: 90%;
    max-width: 450px;
    z-index: 2;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    transform: translate(-50%, -50%);
    padding: 10px;
}

.confirmationPopupTitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--new-text-color);
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.confirmationPopupDescription {
    color: var(--new-off-text-color);
    font-size: 15px;
    margin: 10px 0px;
}

.confirmationPopupButtonWrapper, .confirmationPopupButtonWrapperOneButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 20px;
}

.confirmationPopupButtonWrapperOneButton {
    grid-template-columns: 1fr;
}

.confirmationPopupButton {
    height: 40px;
}

.confirmationPopupSpinnerContainer {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.confirmationPopupSpinner {
    
}

/* .confirmationPopupInput {
    height: 40px;
} */
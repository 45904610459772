

.gradingPageWrapper {
    overflow-y: auto;
}

.gradingOverviewWrapper {
    padding: 25px;
    overflow: hidden;
    overflow-y: auto;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
}

.gradingGraphWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 25px 0px;
    height: auto;
    align-items: center;
}

@media(max-width: 1000px) {
    .gradingGraphWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
}

@media(max-width: 700px) {
    .gradingGraphWrapper {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

@media(max-width: 550px) {
    .gradingGraphWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        padding-bottom: 0px;
    }
}

.gradingGraphTextContainer {
    width: 100%;
    max-width: 350px;
    justify-self: center;
    padding: 30px 0px;
    padding-top: 15px;
    grid-column: 1;
}

.gradingGraphTextHeader {

}

.gradingGraphTextBody {
    margin: 0px;
}

.gradingGraphContainer {
    /* width: 90%; */
    /* border: 1px solid #cccccc; */
    border-radius: 10px;
    height: 250px;
    position: relative;
    grid-row: 1;
    grid-column: 2;
    width: 90%;
}

@media(max-width: 1000px) {
    .gradingGraphTextBody {
        font-size: 14px;
    }

    .gradingGraphContainer {
        grid-column: 1;
        height: 200px;
        width: 100%;
        justify-self: center;
        max-width: 400px;
    }
}

@media(max-width: 700px) {
    .gradingGraphContainer {
        grid-column: 2;
        width: 100%;
        height: 250px;
    }
}

@media(max-width: 550px) {
    .gradingGraphContainer {
        grid-column: 1;
        height: 200px;
        width: 100%;
        justify-self: center;
    }
}

.performanceLoadingSpinnerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gradingPreviousCompletionsContainer {
    background-color: white;
    border-radius: 25px 25px 0px 0px;
    padding: 25px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    min-height: calc(100% - 410px);
}

@media(max-width: 425px) {
    .gradingPreviousCompletionsContainer {
        padding: 20px 15px;
    }
}

.gradingPreviousCompletionsHeaderContainer {
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 25px;
}

.smallerIcon {
    height: 30px;
    width: 30px;
}

.gradingPreviousCompletionsHeaderText {
    margin: 0px;
    font-size: 26px;
}

.gradingPreviousCompletionsHeaderButton {
    height: 40px;
    font-size: 14px;
    max-width: 225px;
    width: 100%;
    justify-self: end;
}

@media(max-width: 1000px) {
    .gradingPreviousCompletionsHeaderContainer {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
    }

    .gradingPreviousCompletionsHeaderButton {
        justify-self: start;
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 10px;
    }
}

@media(max-width: 700px) {
    .gradingPreviousCompletionsHeaderContainer {
        grid-template-columns: auto auto 1fr;
        grid-template-rows: 1fr;
    }

    .gradingPreviousCompletionsHeaderButton {
        justify-self: end;
        grid-column-start: 3;
        grid-column-end: 3;
        margin-top: 0px;
    }
}

@media(max-width: 550px) {
    .gradingPreviousCompletionsHeaderContainer {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
    }

    .gradingPreviousCompletionsHeaderButton {
        justify-self: start;
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 10px;
    }
}

.gradingToolbarContainer {
    /* padding: 10px; */
    overflow: hidden;
    padding-bottom: 0px;
    border: 1px solid #cacece;
    border-radius: 10px;
    margin-bottom: 10px;
}

.gradingToolbarHeaderContainer {
    width: 100%;
    height: 30px;
    background-color: #676767;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.gradingToolbarHeaderIcon {
    color: white;
    height: 18px;
    width: 18px;
    padding: 0px 10px;
}

.gradingToolbarHeaderText {
    margin: 0px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    /* margin-left: 5px; */
}

.gradingToolbarBodyWrapper {
    padding: 10px;
}

.gradingToolbarOptionWrapper {
    display: inline-block;
    width: 100%;
    height: fit-content;
    /* max-width: 250px; */
    width: fit-content;
    margin-right: 25px;
    vertical-align: top;
    /* margin-bottom: 10px; */
}

.gradingToolbarOptionTitle {
    display: block;
    margin: 0px;
    font-size: 14px;
    margin-bottom: 5px;
}

.gradingToolbarToggleContainer {
    position: relative;
    height: 32px;
    width: 57px;
    background-color: #d0d0d0;
    border-radius: 5px;
    border: 1px solid #cacece;
    cursor: pointer;
    transition-duration: 200ms;
}

.gradingToolbarToggleContainerEnabled {
    background-color: var(--primary-color);
}

.gradingToolbarToggleSwitch {
    background-color: white;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    border-radius: 5px;
    transition-duration: 200ms;
}

.gradingToolbarToggleSwitchEnabled {
    left: 30px;
}

.gradingToolbarTextSizeOptionsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: fit-content;
    column-gap: 5px;
}

.gradingToolbarTextSizeOption {
    font-size: 16px;
    margin: 0px;
    height: 32px;
    width: 32px;
    border-radius: 5px;
    border: 1px solid #cacece;
    display: grid;
    align-items: center;
    justify-items: center;
    color: black;
    padding: 0px;
}

.gradingToolbarTextOptionSmall {
    font-size: 12px;
}

.gradingToolbarTextOptionLarge {
    font-size: 20px;
}

.gradingToolbarTextOptionSelected {
    background-color: var(--primary-color);
    color: white;
}

.gradingMetricContainer {
    display: inline-grid;
    grid-template-columns: 55px auto;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    column-gap: 10px;
    align-items: center;
}

.gradingScoreContainer {
    width: 33%;
}

.gradingDateContainer {
    width: calc(67% - 10px);
    margin-left: 10px;
}

@media(max-width: 1000px) {
    .gradingScoreContainer {
        width: 100%;
    }

    .gradingDateContainer {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
}

@media(max-width: 700px) {
    .gradingScoreContainer {
        width: 33%;
    }

    .gradingDateContainer {
        width: calc(67% - 10px);
        margin-left: 10px;
        margin-top: 0px;
    }
}

@media(max-width: 550px) {
    .gradingScoreContainer {
        width: 100%;
    }

    .gradingDateContainer {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
}

.gradingMetricIconWrapper {
    background-color: var(--primary-color);
    border-radius: 10px;
    height: 55px;
    width: 55px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.gradingMetricIcon {
    color: white;
    font-size: 28px;
}

.gradingMetricTextWrapper {
    position: relative;
    top: 2px;
}

.gradingMetricTextHeader {
    font-size: 14px;
    margin: 0px;
}

.gradingMetricTextMain {
    display: inline-block;
    margin: 0px;
    font-size: 26px;
    font-family: apparat, sans-serif;
    font-weight: 300;
}

.gradingMetricTextSub {
    display: inline-block;
    margin: 0px;
    font-size: 14px;
}

@media(max-width: 550px) {
    .gradingMetricTextHeader {
        font-size: 12px;
    }

    .gradingMetricTextMain {
        font-size: 20px;
    }
}

.gradingFeedbackLoaderWrapper {
    position: absolute;
    left: 50%;
    margin-top: 50px;
    transform: translateX(-50%);
    /* top: 100px; */
}

.gradingFeedbackQuestionContainer {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-top: 20px;
}

.feedbackQuestionHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.feedbackQuestionNumber {
    color: var(--primary-color);
    font-weight: 900;
    font-size: 40px;
    margin: 0px 0px;
    display: inline-block;
    padding: 0px 10px;
}

.feedbackQuestionTitle {
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

.feedbackQuestionSummaryContainer {
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-top: 10px;
    display: grid;
    align-items: center;
    overflow: hidden;
}

.feedbackQuestionSummaryHeaderWrapper {
    background-color: #e8e8e8;
    display: grid;
    grid-template-columns: auto 1fr;
}

.feedbackQuestionSummaryExpandButton {
    width: 20px;
    margin-left: 10px;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    border: 0px;
}

.feedbackQuestionSummaryExpandButton::before, .feedbackQuestionSummaryExpandButton::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 15px;
    width: 2px;
    background-color: var(--primary-color);
    /* transition-duration: 200ms; */
}

.feedbackQuestionSummaryExpandButton::before {
    transform: translate(-50%, -50%);
}

.feedbackQuestionSummaryExpandButton::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.feedbackQuestionSummaryExpandButtonEnabled::before {
    transform: translate(-50%, -50%) rotate(90deg);
}

.feedbackQuestionSummaryTitle {
    margin: 0px;
    font-weight: bold;
    font-size: 16px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.feedbackQuestionSummaryTextWrapper {
    /* transition-duration: 200ms; */
}

.feedbackQuestionSummaryHidden {
    height: 0px;
    opacity: 0;
}

.feedbackQuestionSummaryText {
    font-size: 14px;
    margin: 0px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: calc(100% - 10px);
}

.feedbackQuestionSummaryTextMedium {
    font-size: 16px;
}

.feedbackQuestionSummaryTextLarge {
    font-size: 18px;
}

.feedbackQuestionGradeContainer {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cccccc;
    display: grid;
    align-items: center;
    height: 50px;
    padding-left: 10px;
}

.feedbackQuestionCorrect {
    background-color: #EAF8E9;
}

.feedbackQuestionIncorrect {
    background-color: #fedede;
}

.feedbackQuestionPartiallyCorrect {
    background-color: #fcf3e3;
}

.feedbackQuestionGradeText {
    margin: 0px;
    color: var(--text-color);
    font-family: apparat, sans-serif;
    width: 90%;
}

.feedbackAnswerOptionContainer {
    display: grid;
    grid-template-columns: 40px auto;
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
    min-height: 70px;
    align-items: center;
}

.feedbackAnswerContainerCorrect {
    background-color: #EAF8E9;
}

.feedbackAnswerContainerOrange {
    background-color: #fcf3e3;
}

.feedbackAnswerContainerIncorrect {
    background-color: #fedede;
}

.feedbackAnswerContainerNeutral {
    background-color: #F0F0F0;
}

.feedbackAnswerOptionCheckboxWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
}

.feedbackCheckboxWrapperCorrect {
    background-color: #45813b;
}

.feedbackCheckboxWrapperIncorrect {
    background-color: #a13636;
}

.feedbackCheckboxWrapperOrange {
    background-color: #b77f36;
}

.feedbackCheckboxWrapperNeutral {
    background-color: #808692;
}

.feedbackAnswerOptionCheckbox {
    height: 16px;
    width: 16px;
    pointer-events: none;
    background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.feedbackAnswerOptionCheckboxCheck {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
}

.feedbackAnswerTextWrapper {
    padding-left: 10px;
}

.feedbackAnswerText {
    margin: 0px;
    font-weight: 600;
    padding-top: 5px;
}

.feedbackAnswerSubText {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 5px;
}

.expandQuestionAnswersButton {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    font-size: 14px;
}

@media(max-width: 550px) {
    .feedbackQuestionTitle {
        font-size: 16px;
    }

    .feedbackQuestionGradeText {
        font-size: 14px;
    }

    .feedbackAnswerText {
        font-size: 14px;
    }

    .feedbackAnswerSubText {
        font-size: 12px;
    }

    .expandQuestionAnswersButton {
        font-size: 12px;
    }
}


.incompleteBlockPopupPageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.incompleteBlockPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.incompleteBlockPopupContainer {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: calc(95% - 20px);
    max-width: 450px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    padding-top: 5px;
}

.incompleteBlockPopupHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
}

.incompleteBlockPopupId {
    font-size: 44px;
    color: #0B335D;
    margin: 0px;
}

.incompleteBlockPopupTitle {
    margin: 0px;
    font-size: 22px;
    color: var(--new-text-color);
}

.incompleteBlockPopupMetaWrapper {
    display: grid;
    font-size: 14px;
    margin: 0px;
    height: fit-content;
    border-bottom: 1px solid var(--new-off-text-color);
    padding-bottom: 10px;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
}

.incompleteBlockPopupIcon {
    color: #0B335D;
    height: 22px;
    width: 22px;
}

.incompleteBlockPopupMetaText {
    margin: 0px;
    color: var(--new-text-color);
}

.incompleteBlockPopupHeader {
    margin: 10px 0px;
    margin-bottom: 5px;
    color: var(--new-text-color);
}

.incompleteBlockPopupText {
    margin-top: 7px;
    font-size: 14px;
    color: var(--new-off-text-color)
}

.incompleteBlockPopupOptionsWrapper {
    width: 100%;
    background-color: var(--primary-background-color);
    border-radius: 3px;
    /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25); */
    padding: 10px;
    max-height: 250px;
    overflow-y: auto;
}

.incompleteBlockPopupOptionContainer {
    background-color: white;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 3px;
    color: var(--new-text-color);
    padding: 10px;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.incompleteBlockPopupOptionSelected {
    background-color: #0B335D;
    color: white;
}

.incompleteBlockPopupOptionContainer:nth-last-child(1) {
    margin-bottom: 0px;
}

.incompleteBlockPopupOptionText {
    margin: 0px;
}

.incompleteBlockPopupButtonWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 10px;
}

.incompleteBlockPopupLoadingWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
}

.incompleteBlockPopupButton {
    height: 40px;
    border-radius: 3px;
    border: 0px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    cursor: pointer;
    transition-duration: 150ms;
}

.incompleteBlockPopupButtonDisabled {
    /* background-color: var(--new-primary-background-color); */
    /* color: var(--new-secondary-off-text-color); */
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
}

.incompleteBlockPopupButtonActive {
    color: white;
    background-color: #0B335D;
}



#create-test-page {
  button {
    width: 170px;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .input-error {
    color: red;
    position: absolute;
    font-size: .9rem;
    left: 10px;
  }

  header {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: linear-gradient(rgb(248, 248, 248), rgb(238, 238, 238));
    border-bottom: 1px solid rgb(209, 209, 209);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    button {
      background-color: #fff;
      margin: 0 1rem;

      &:hover {
        background-color: darken(#fff, 2.5%);
      }
    }
  }

  #mobile-menu {
    display: none;

    button {
      color: #000;
      width: auto;
      padding: 0.25rem 1.5rem;
      border: 1px solid rgb(196, 196, 196);
    }

    .dropdown-menu {
      overflow: hidden;
      padding-bottom: 1rem;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);

      button {
        padding: 0.5rem 1rem;
        border: none;
        border-right: none;
        border-bottom: 1px solid rgb(206, 206, 206);
        width: 85%;
      }
    }
  }

  main {
    background-color: #2d486e;
    padding: 1rem 2% 1rem 2%;
    min-height: calc(100vh - 59px);
  }

  .options-container {
    background-color: rgb(253, 253, 253);
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    padding: 1rem;
    width: 95%;
    margin: 0 auto;
    max-width: 1300px;
    font-size: 1.2rem;
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0.35);

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0;
      display: inline-block;
      color: rgb(9, 15, 26);
    }

    section {
      padding: 1.25rem 0;
      border-bottom: 1px solid rgb(218, 218, 218);

      &:first-child {
        padding-top: 0.5rem;
      }
    }
  }

  .categories-section {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .checkboxes-container {
    display: inline-block;
    margin-left: 1rem;

    div {
      display: inline-block;
    }

    input {
      margin: 0 5px;
    }
    label {
      margin-right: 20px;
    }
  }

  .number-of-questions-section {
    display: flex;
    align-items: center;
    
    div {
      display: inline-block;
      position: relative;
      width: 80%;
    }

    span {
      color: rgb(92, 92, 92);
    }

    label {
      display: none;
    }
    
    input[type="number"] {
      margin: 0 1rem;
      background-color: rgb(245, 245, 245);
      border: 1px solid rgb(189, 189, 189);
      font-size: 1.1rem;
      border-radius: 4px;
    }
  }

  .categories-container {
    width: 55%;
    margin-right: 40px;
  }

  .select-buttons {
    display: inline-block;
    margin-left: 1rem;

    button {
      width: auto;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid rgb(221, 221, 221);
      border-radius: 5px;
      padding: 0.1rem 0.5rem;
      margin-right: 10px;

      &:hover {
        background-color: rgb(245, 245, 245);
      }
    }
  }

  .two-column {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }

  .categories {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    padding-left: 1rem;

    div {
      width: 50%;
    }

    div:first-child {
      margin-right: 20px;
    }

    li {
      padding: 0.15rem 0;
      list-style-type: none;
    }

    label {
      margin-left: 10px;

      span {
        color: rgb(73, 95, 143);
      }
    }

    .category {
      margin: 0.5rem;
      border: 1px solid rgb(209, 209, 209);
      padding: 0.5rem;
      width: 100%;
    }

    //  For displaying sub category only if parent is checked  //
    // .sub-category {
    //   padding-left: 1.1rem;
    //   display: none;
    // }

    // input[type="checkbox"]:checked + label + .sub-category {
    //   display: block;
    // }
  }

  .progress-bars-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 40%;

    h2 {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      color: rgb(69, 74, 83);
    }

    .CircularProgressbar {
      width: 60%;
    }
  }

  .percent-completed,
  .average-grade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem;
  }

  .start-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    button {
      background: lighten(#275ca7, 10%);
      border: gray;

      &:hover {
        background: #275ca7;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .progress-bars-container {
      width: 90%;
      margin: 1rem auto;
    }

    .categories-container {
      width: 100%;
      margin: 0 auto;
    }

    .categories {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 800px) {
    #desktop-menu {
      display: none;
    }

    #mobile-menu {
      display: block;
    }

    .number-of-questions-section {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      div {
        margin: .5rem 0 0 .25rem;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .categories-container {
      width: 100%;
    }
    .select-buttons {
      margin-top: 0.5rem;
    }
    .categories {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      width: 100%;

      .category {
        width: 100%;
      }
      div {
        width: 100%;
      }
    }
    .progress-bars-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    header {
      padding-left: 7%;
    }
    .categories {
      display: flex;
      flex-direction: column;
    }
    .progress-bars-container {
      h2 {
        font-size: 0.7rem;
      }
    }
    .percent-completed {
      padding: 0.25rem 0.5rem;
    }

    .number-of-questions-section {
      input[type="number"] {
        font-size: 1.1rem;
        margin-left: 1rem;
      }

      span {
        font-size: 1rem;
      }

      div {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 280px) {
    .progress-bars-container {
      flex-direction: column;
    }
  }
}

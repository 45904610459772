#test {
  $dark-blue: #004976;
  $light-blue: #d7ddec;
  $gray: #e2e2e2;

  width: 100%;
  // min-height: 100vh;
  height: 100%;
  background: linear-gradient(#fff, $light-blue);
  display: flex;
  overflow: auto; 

  .bg-dark {
    background-color: #000 !important;
  }

  .questions-list {
    width: 100px;
    min-width: 100px;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    // border-right: 10px solid rgb(245, 245, 245);
    list-style-position: inside;
    background-color: #fff;
    display: block;
    z-index: 15;

    ul {
      padding-left: 0;
    }

    li {
      width: 100%;
      padding-left: 1rem;
      cursor: pointer;
      position: relative;

      &:nth-child(even) {
        background-color: $gray;
      }
    }
    .current {
      background-color: $dark-blue !important;
      color: #fff !important;
    }

    .completedListItem::marker {
      color: transparent;
    }

    .questionListItem > p {
      margin: 0px !important;
      display: inline-grid;
      width: 25px;
      align-items: center;
      justify-items: center;
    }

    .questionListText {

    }

    .lastGroupedQuestion > p {
      border: 1px solid black;
      border-top: 0px;
    }
    .middleGroupedQuestion > p {
      border: 1px solid black;
      border-top: 0px;
      border-bottom: 0px;
    }
    .firstGroupedQuestion > p {
      border: 1px solid black;
      border-bottom: 0px;
    }

    .listMarkedFlag {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 25px;
      width: 25px;
      color: red;
      filter: invert(35%) sepia(88%) saturate(6875%) hue-rotate(347deg) brightness(85%) contrast(82%);
    }
  }

  .dark-questions-list {
    li {
      width: 100%;
      padding-left: 1rem;

      &:nth-child(even) {
        background-color: gray;
      }
    }
    .current {
      background-color: #a2ddff;
      color: #000;
    }

    .completedListItem {
      list-style-type: none;
      padding-left: 38px;
    }
  }

  .main {
    width: 100%;
    border-left: 1px solid rgb(187, 187, 187);
    overflow: auto;
  }

  header {
    background-color: $dark-blue;
    color: #fff;
    height: 60px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    img {
      max-width: 80px;
    }
  }

  .dark-header, .dark-footer {
    // background-color: #051f71;
    background-color: $dark-blue;
  }

  .item-info-container {
    display: flex;
    align-items: center;
  }

  #open-questions-btn {
    display: none;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 2px;
    background-color: #fff;
    font-weight: bold;
    color: rgb(31, 31, 31);
    padding: 6px 4px;
  }

  .flag-container {
    margin-left: 20px;
    display: flex;
    align-items: center;

    img {
      width: 42px;
      color: red;
      filter: invert(35%) sepia(88%) saturate(6875%) hue-rotate(347deg) brightness(85%) contrast(82%);
      transform: rotate(-10deg);
      position: relative;
      bottom: 5px;
    }

    input {
      position: relative;
      left: 10px;
    }

    label span {
      position: relative;
      right: 10px;
      top: 2px;
    }
  }

  .nav-buttons {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;

    .previous-button,
    .next-button {
      width: 80px;
      height: 50px;
      padding: 0.5rem;
      border: 1px solid rgba(128, 128, 128, 0.507);
      background-color: rgba(0, 0, 0, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 5px;
      color: #fff;

      img {
        width: 20px;
      }
    }

    .previous-button {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .item-info {
    padding: 5px;
    padding-bottom: 0;
    height: 40px;
    border: 1px solid darken($light-blue, 15%);
    border-radius: 2px;
    max-width: 140px;

    p {
      padding: 0;
      margin: 0;
      line-height: 1.15;

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  .tools {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    padding-right: 20px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 3px;
      width: 88px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .tool-image {
    max-width: 25px !important;
  }

  .footer-button {
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    color: white;
  }

  #desktop-tools {
    button {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      color: #fff;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
  }

  #mobile-tools {
    display: none;
    position: relative;

    .dropdown-menu {
      right: 0;
      top: calc(100% + 2px);
    }

  }

  .tools-button {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0) !important;
  }

  .tools-button:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
    border: 1px solid rgba(0, 0, 0, 0.8) !important;
  }

  .zoom-options {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-content: center;

    input {
      display: none;
    }

    span {
      display: inline-block;
      vertical-align: top;
      width: 21px;
      height: 20px;
      text-align: center;
      border: 1px solid rgb(226, 226, 226);
      cursor: pointer;
    }

    label:first-child {
      span {
        line-height: 1.6;
        border-radius: 5px 0 0 5px;
      }
    }
    label:nth-child(2) {
      span {
        font-size: 14px;
        line-height: 1.3;
        border-left: none;
        border-right: none;
      }
    }
    label:last-child {
      span {
        font-size: 17px;
        line-height: 1.1;
        border-radius: 0 5px 5px 0;
      }
    }

    input[type="radio"]:checked ~ span{
      background-color: $light-blue;
      color: #000;
    }
  }

  .question-container {
    width: 100%;
    min-height: calc(100vh - 120px);
    position: relative;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 1fr auto;
    overflow: hidden;

    .testWrapper {
      display: inline-block;
      vertical-align: top;
      padding: 1rem;
      height: calc(100%);
      overflow-y: auto;
    }

    @media(max-width: 900px) {
      .desktopToolsWrapper {
        display: none;
      }
    }

    .mobileLabsWrapper {
      display: none;
    }

    .mobileCalculatorWrapper {
      display: none;
    }

    @media(max-width: 900px) {
      .mobileLabsWrapper {
        display: initial;
      }
  
      .mobileCalculatorWrapper {
        display: initial;
      }
    }

    .button-container {
      max-width: 550px;
      display: grid;
      justify-items: end;
      align-items: center;

      button {
        margin-top: 1.5rem;
        background: linear-gradient(#659dc2, $dark-blue);
        color: #fff;
        border: 1px solid lighten($dark-blue, 2%);
        border-radius: 3px;

        &:hover {
          background: linear-gradient($dark-blue, #659dc2);
        }

        &:disabled {
          background: linear-gradient(#76a9ca, #45697e);
          border: 1px solid #45697e;
        }
      }
    }
  }

  .question {
    max-width: 600px;
  }

  .question-image {
    height: 100%;
    max-width: 600px;
    max-height: 250px;
    object-fit: cover;
    object-position: center;
    display: block;
    margin-bottom: 10px;
  }

  .answers {
    display: inline-block;
    min-width: 200px;
    max-width: 65%;
    border: 1px solid $dark-blue;
    border-bottom: 3px solid $dark-blue;
    padding: .5rem;

    div {
      display: flex;
      cursor: pointer;
      
      &:hover {
        background-color: rgba(150, 150, 150, 0.25);

        input {
          transform: scale(1.25);
        }
      }
    }

    input {
      margin-right: 5px;
      transition-duration: 150ms;

      // &:hover {
      //   transform: scale(1.25);
      // }
    }
  }

  .answerListDisabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .questionCompletedText {
    font-weight: bold;
    margin-top: 10px;
    font-size: 14px;
  }

  footer {
    background-color: $dark-blue;
    color: #fff;
    height: 60px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .item-info {
      display: inline-block;
      width: auto;
      max-width: 400px;
    }
  }

  @media screen and (max-width: 900px) {
    #desktop-tools {
      display: none;
    }

    #mobile-tools {
      display: block;
      padding-right: 0;

      li button {
        padding: .6rem;
      }

      button img {
        margin-right: 8px;
      }

      li {
        border-bottom: 1px solid rgb(211, 211, 211);
      }

      li:last-child {
        border: none;
        padding-top: 10px;
        text-align: center;
        font-weight: normal;
      }

      .zoom-options {
        label {
          display: flex;

          span {
            width: 35px;
            height: 20px;
          }

          &:first-child span {
            font-size: 10px;
            line-height: 1.8;
          }
        }

        input[type="radio"]:checked ~ span{
          background-color: $light-blue;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    header {
      font-size: 9px;
    }

    #open-questions-btn {
      display: block;
    }

    .main {
      border-left: 0px;
    }

    .questions-list {
      display: none;
      position: absolute;
      box-shadow: 2px 0 20px rgba(0, 0, 0, .2);
    }

    .nav-buttons {
      .previous-button, .next-button {
        height: 40px;
        width: 60px;
        padding: 0.25rem;

        img {
          width: 15px;
        }
      }
    }

    .flag-container {
      margin: 0;

      img {
        width: 35px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    #mobile-tools .btn {
      font-size: 10px;
      font-weight: bold;
    }

    #open-questions-btn {
      margin-right: 5px;
    }

    .flag-container {
      margin-left: 10px;
      img {
        width: 25px;
      }
      input {
        left: 3px;
      }
      label span {
        right: 6px;
      }
    }

    .item-info p {
      line-height: 1.4;
    }

    .nav-buttons {
      font-size: 10px;
      .previous-button, .next-button {
        height: 40px;
        width: 45px;
        padding: 0.25rem;
  
        img {
          width: 15px;
        }
      }
    }

    footer {
      font-size: 9px;

      .tools {
        font-size: 10px;
        padding-right: 0;
      }
    }
  }

  .mobileLabsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
  }

  @media(min-width: 900px) {
    .mobileLabsWrapper {
      display: none;
    }
  }

  .mobilePopupBackdrop {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobilePopupContainer {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    max-height: 80%;
    width: 95%;
    max-width: 500px;
    background: var(--background-color);
    overflow: hidden;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  }

  .mobilePopupHeaderContainer {
    // background-color:
    display: grid;
    grid-template-columns: 1fr auto;
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #004976;
    padding: 5px;
  }

  .mobilePopupHeaderText {
    font-size: 24px;
    color: white;
    margin: 5px 0px;
    align-self: center;
  }

  .mobilePopupCloseButton {
    width: 35px;
    height: 35px;
    align-self: center;
    border: 0px;
    border-radius: 5px;
    position: relative;
  }

  .mobilePopupCloseButton::before, .mobilePopupCloseButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 20px;
    border-radius: 3px;
    background-color: black;
  }

  .mobilePopupCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .mobilePopupCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .mobilePopupContentContainer {
    padding: 5px;
  }
}

.testPopupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
}

.testPopupBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.testPopupContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  height: fit-content;
  max-height: 95%;
  width: 95%;
  max-width: 600px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  z-index: 2;
}

.testPopupHeaderContainer {
  background-color: #004976;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  height: 50px;
}

.testPopupHeaderText {
  margin: 0px;
  color: white;
  font-size: 24px;
  font-weight: 400;
  padding-left: 10px;
}

.testPopupCloseButton {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  height: 100%;
  width: 100%;
}

.testPopupCloseButton::before, .testPopupCloseButton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  width: 2px;
  border-radius: 2px;
  height: 20px;
}

.testPopupCloseButton::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.testPopupCloseButton::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.testPopupBodyContainer {
  padding: 10px;
  min-height: 300px;
}

.testPopupBodyText {
  font-size: 15px;
  opacity: 0.9;
  color: var(--labs-text-color);
}

.testPopupInputLabel {
  color: var(--labs-text-color);
}

.testPopupInput {
  height: 40px;
  width: 100%;
  padding-left: 10px;
  // border: 1px solid #004976;
  border: 0px;
  margin: 0px;
  background-color: var(--input-color);
  border: 1px solid #808080;
  color: var(--labs-text-color);
}

.testPopupFooterContainer {
  background-color: #004976;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: end;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
}

.testPopupFooterLoaderWrapper {
  width: 100%;
  max-width: 200px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.testPopupFooterButton {
  height: 100%;
  width: 200px;
  border: 0px;
  color: black;
}

@media(max-width: 455px) {
  .testPopupFooterContainer {
    grid-template-columns: 1fr 1fr;
  }

  .testPopupFooterButton {
    width: 100%;
  }
}

.testPopupPrimaryButton {

}

.testPopupSecondaryButton {

}

.test-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.test-footer-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
}

.printReceiptButtonContainer {
  position: sticky;
  bottom: 0;
  background-color: #e3e3e3;
  border-top: 1px solid #bbbbbb;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  padding: 10px;
  height: 60px;
  // width: calc(100% - 0px);
}

@media print {
  .printReceiptButtonContainer {
      display:none;
  }
}

.printReceiptButton {
  height: 40px;
  width: 100%;
  max-width: 300px;
  justify-self: start;
  font-size: 14px;
}

.printReceiptButton:nth-child(1) {
  justify-self: end;
}


.errorPopupContainer {
    position: fixed;
    top: 10px;
    width: fit-content;
    max-width: calc(100% - 20px);
    right: 10px;
    /* height: 50px; */
    background-color: white;
    z-index: 100;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 40px auto;
    overflow: hidden;
}

.errorPopupCloseButton {
    border: 0px;
    background-color: #D50000;
    position: relative;
}

.errorPopupCloseButton::before, .errorPopupCloseButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
    background-color: white;
}

.errorPopupCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.errorPopupCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.errorPopupTextWrapper {
    padding: 10px;
}

.errorPopupTitle {
    margin: 0px;
    font-size: 18px;
    color: #D50000;
    font-weight: 900;
    margin-bottom: 2px;
}

.errorPopupText {
    margin: 0px;
    font-size: 14px;
    /* font-weight: bold; */
}
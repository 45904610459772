

.practiceModePopupPageContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.practiceModePopupBackdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.practiceModePopupWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    max-height: 550px;
    max-width: 700px;
    background: linear-gradient(#fff, #d7ddec);
    z-index: 2;
}

.practiceModeAnswerPopupHeaderContainer {
    height: 50px;
    width: 100%;
    background-color: #004976;
    display: grid;
    align-items: center;
}

.practiceModeAnswerPopupHeaderText {
    font-size: 20px;
    color: white;
    margin: 0px;
    padding-left: 10px;
}

.practiceModeAnswerPopupBodyContainer {
    height: calc(100% - 100px);
    padding: 0px 20px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
}


.settingsPageWrapper {
    padding: 25px;
    overflow-y: auto;
}

@media(max-width: 450px) {
    .settingsPageWrapper {
        padding: 25px 15px;
    }
}

.settingsPageHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 10px;
    margin-bottom: 20px;
}

.settingsPageHeaderIcon {
    height: 30px;
    width: 30px;
}

.settingsPageHeaderText {
    margin: 0px;
    font-size: 28px;
}

.settingsPageLogoutButton {
    width: 200px;
    height: 40px;
}

@media(max-width: 850px) {
    .settingsPageHeaderWrapper {
        grid-template-columns: auto 1fr;
        row-gap: 10px;
    }

    .settingsPageLogoutButton {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.settingsProfileDetailsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.settingsHeader {
    font-size: 22px;
    font-weight: bold;
    /* margin-bottom: 15px; */
}

.settingsProfileDetailsHeader {
    border-bottom: 2px solid var(--primary-color);
    width: fit-content;
    grid-column-start: 1;
    grid-column-end: 5;
}

@media(max-width: 1250px) {
    .settingsProfileDetailsWrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .settingsProfileDetailsHeader {
        grid-column-end: 4;
    }
}

@media(max-width: 1000px) {
    .settingsProfileDetailsWrapper {
        grid-template-columns: 1fr 1fr;
    }

    .settingsProfileDetailsHeader {
        grid-column-end: 3;
    }
}

@media(max-width: 850px) {
    .settingsProfileDetailsWrapper {
        grid-template-columns: 1fr;
    }

    .settingsProfileDetailsHeader {
        grid-column-end: 2;
    }
}

.settingsProfileDetailWrapper {

}

.settingsProfileDetailTitle {
    font-size: 12px;
    margin: 0px;
    margin-bottom: 3px;
}

.settingsProfileDetailText {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
}

.settingsActionsWrapper {
    display: grid;
    grid-template-areas:
        "a b c"
        "a d e"
        "f . .";
    grid-gap: 10px;
    /* grid-template-columns: 1fr 1fr 1fr; */
    margin-top: 25px;
}

@media(max-width: 1250px) {
    .settingsActionsWrapper {
        grid-template-areas:
            "a b"
            "a d"
            "c f"
            "e .";
    }
}

@media(max-width: 900px) {
    .settingsActionsWrapper {
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "e"
            "f";
    }
}

.accountSettingsCard {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #cccccc;
    transition-duration: 250ms;
    padding: 15px;
    position: relative;
    color: black;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
}

.accountSettingsCardTitle {
    margin: 0px;
    /* color: var(--new-case-text-color); */
    transition-duration: 250ms;
    font-weight: bold;
    font-size: 22px;
}

.accountSettingsToggleDescription {
    font-size: 13px;
    margin-top: 10px;
    /* color: var(--new-off-text-color); */
    transition-duration: 250ms;
}

@media(max-width: 600px) {
    .accountSettingsToggleDescription {
        margin-top: 5px;
    }
}

.accountSettingsOrderedList {
    display: grid;
    list-style-type: none;
    grid-template-columns: 20px 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap: 10px;
    width: calc(100% - 10px);
    padding: 0px;
    column-gap: 10px;
}

.accountSettingsOrderedListNumber {
    font-size: 24px;
    margin: 0px;
    color: var(--primary-color);
    transition-duration: 250ms;
    font-weight: bold;
    justify-self: center;
}

.accountSettingsOrderedListItem {
    white-space: pre-wrap;
    word-wrap: break-word;
    /* color: var(--new-case-text-color); */
    transition-duration: 250ms;
    font-weight: 500;
    font-size: 13px;
    width: calc(100% - 10px);
    height: fit-content;
    vertical-align: top;
    position: relative;
}

.accountSettingsOrderedListItem > p {
    height: fit-content;
    margin: 0px;
}

.accountSettingsButton {
    height: 40px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    background-color: var(--primary-color);
    transition-duration: 250ms;
    color: white;
    font-size: 14px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    cursor: pointer;
}

.accountSettingsDropdown {
    height: 40px;
    width: 100%;
    background-color: var(--new-secondary-background-color);
    transition-duration: 250ms;
    cursor: pointer;
    color: var(--new-case-text-color);
    border: 0px;
    border-radius: 3px;
    border: 1px solid #cccccc;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    padding: 0px 5px;
    font-size: 14px;
    /* -webkit-appearance: none; */
}

.settingsPageBackButton {
    height: 40px;
    padding: 0px 25px;
    font-size: 14px;
}

.settingsPageTitle {
    font-size: 28px;
    margin-top: 30px;
    font-weight: bold;
}

.settingsPageSubtitle {
    font-size: 14px;
}

.settingsPageCardContainer {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #cccccc;
    padding: 15px;
    margin-bottom: 10px;
}

.settingsPageFaqTitle {
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
}

.settingsPageFaqBody {
    font-size: 14px;
    margin: 0px;
}

.settingsFeedbackTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
}

.settingsFeedbackInput {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    background-color: #F4F4F4;
    border: 1px solid #cccccc;
    padding: 10px;
    font-size: 14px;
    resize: none;
    margin-top: 5px;
}

.settingsSubmissionSpinnerContainer {
    margin-left: auto;
    display: grid;
    margin-top: 5px;
    height: 40px;
    width: 100%;
    max-width: 200px;
    justify-items: center;
    align-items: center;
}

.settingsFeedbackSubmitButton {
    margin-top: 5px;
    margin-left: auto;
    display: block;
    height: 40px;
    padding: 0px 40px;
    font-size: 14px;
}

.settingsPageInputLabel {
    font-size: 14px;
    /* font-weight: bold; */
    margin: 0px;
    margin-bottom: 5px;
}

.settingsPageInputWrapper {
    position: relative;
    margin-bottom: 5px;
    border-radius: 5px;
    overflow: hidden;
}

.settingsPageInputWrapper:nth-last-child(2) {
    margin-bottom: 10px;
}

.settingsPageInput {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #F4F4F4;
    border: 1px solid #cccccc;
    padding: 10px;
    font-size: 14px;
    resize: none;
}

.settingsPageInputShowButton {
    position: absolute;
    right: 1px;
    height: calc(100% - 2px);
    border-radius: 5px;
    width: 50px;
    background-color: transparent;
    border: 0px;
    top: 1px;
    display: grid;
    align-items: center;
    justify-items: center;
}

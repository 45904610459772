

:root {
    --primary-color: #0b335d;
    --secondary-color: #F79D23;
    --tertiary-color: #e8eff9;
    --primary-background-color: #f2f2f2;
    --secondary-background-color: #ffffff;
    --tertiary-background-color: #e2e2e2;
    --text-color: #303030;
    --unselected-border-color: #979797;
}

.appContainer {
    display: grid;
    grid-template-columns: 300px auto;
    width: 100%;
    background-color: var(--primary-background-color);
    font-family: apparat, sans-serif;
    font-family: apparat-light, sans-serif;
    overflow: hidden;
}

@media(max-width: 700px) {
    .appContainer {
        grid-template-columns: 1fr;
    }
}

/* Begin Sidebar */

.desktopSidebarContainer {
    /* border-radius: 0px 5px 5px 0px; */
    position: sticky;
    top: 0;
    left: 0;
}

.mobileDrawerContainer {
    display: none;
}

@media(max-width: 700px) {
    .desktopSidebarContainer {
        display: none;
    }

    .mobileDrawerContainer {
        display: initial;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 20;
        width: 100%;
        height: 100%;
    }

    .mobileDrawerBackdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 21;
    }

    .mobileDrawerContentWrapper {
        z-index: 22;
        position: absolute;
        top: 0;
        height: 100%;
        width: 80%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
        max-width: 350px;
    }
}

.mobileDrawerCloseButton {
    position: absolute;
    top: 8px;
    right: 5px;
    height: 45px;
    width: 45px;
    cursor: pointer;
    /* background-color: var(--secondary-background-color); */
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    /* border-radius: 5px; */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
}

.mobileDrawerCloseButton::before, .mobileDrawerCloseButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 2px;
    border-radius: 2px;
    /* background-color: var(--primary-color); */
    background-color: white;
}

.mobileDrawerCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.mobileDrawerCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.sidebarContainer {
    background-color: var(--primary-color);
    height: 100%;
    /* background-color: #4e4e4e; */
}

.sidebarLogoText {
    display: block;
    font-weight: 100;
    color: white;
    margin: 0px;
    padding: 15px 10px;
    font-family: apparat-light, sans-serif;
}

.highlightedLogoText {
    font-weight: 300;
    color: var(--secondary-color);
}

.sidebarNavContainer {
    display: block;
}

.sidebarNavButton {
    display: grid;
    height: 45px;
    width: 100%;
    grid-template-columns: 50px auto;
    align-items: center;
    padding: 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    text-align: start;
    font-weight: 300;
    margin: 3px 0px;
}

.sidebarNavButton:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.sidebarNavButtonSelected {
    background-color: var(--primary-background-color);
    color: var(--text-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    cursor: initial !important;
    pointer-events: none;
}

.sidebarNavButtonSelected:hover {
    background-color: var(--primary-background-color);
}

.sidebarNavIcon {
    height: 25px;
    width: 25px;
}

/* End Sidebar */
/* Begin Homepage */

.homepagePageContainer {
    overflow-y: auto;
}

.graphSpinnerWrapper {
    padding: 50px 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    height: 240px;
}

.homepageGraphsWrapper {
    padding: 50px 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
}

.homepageTitleContainer {
    display: grid;
    width: 100%;
    height: 50px;
    grid-template-columns: 50px auto;
    align-items: center;
    margin-bottom: 10px;
}

.homepageTitleIcon {
    height: 35px;
    width: 35px;
    color:var(--text-color);
}

.homepageTitleText {
    margin: 0px;
    font-size: 28px;
}

.mobileDrawerToggleButton {
    position: absolute;
    top: 27px;
    right: 15px;
    z-index: 10;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: var(--secondary-background-color);
    /* border: 1px solid var(--unselected-border-color); */
    border: 0px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media(min-width: 701px) {
    .mobileDrawerToggleButton {
        display: none;
    }
}

.mobileDrawerBurgerIcon, .mobileDrawerBurgerIcon::before, .mobileDrawerBurgerIcon::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-color);
    border-radius: 3px;
    height: 2px;
    width: 20px;
}

.mobileDrawerBurgerIcon {
    top: 50%;
}

.mobileDrawerBurgerIcon::before {
    content: "";
    top: calc(50% - 6px);
}

.mobileDrawerBurgerIcon::after {
    content: "";
    top: calc(50% + 6px);
}

.homepageGraphContainer {
    width: 50%;
    display: inline-grid;
    height: fit-content;
    margin: 20px 0px;
    /* grid-template-columns: 150px auto; */
    grid-template-columns: 200px auto;
    grid-template-rows: auto auto;
}

.homepageGraphWrapper {
    position: relative;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    height: fit-content;
    align-self: center;
}

.homepageGraphValuesWrapper {
    position: absolute;
    top: 52.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-template-rows: 1fr 1fr;
}

.homepageGraphValuesWrapper:nth-child(2) {
    grid-template-rows: 1fr;
}

.homepageGraphValue {
    margin: 0px;
    align-self: end;
    text-align: center;
}

.homepageGraphValue:nth-child(2) {
    align-self: center;
}

.homepageGraphSubvalue {
    align-self: start;
    text-align: center;
    font-size: 18px;
}

.homepageGraphTitle {
    font-size: 28px;
    align-self: end;
    padding-left: 25px;
}

.homepageGraphDescription {
    font-size: 14px;
    padding-left: 25px;
    padding-right: 10px;
    margin: 0px;
}

@media(max-width: 1175px) {
    .homepageGraphContainer {
        grid-template-columns: 150px auto;
    }

    .homepageGraphValue {
        font-size: 32px;
    }

    .homepageGraphSubvalue {
        font-size: 16px;
    }
}

@media(max-width: 1000px) {
    .homepageGraphContainer {
        grid-template-columns: 200px auto;
        width: 100%;
    }

    .homepageGraphValue {
        font-size: 36px;
    }

    .homepageGraphSubvalue {
        font-size: 18px;
    }
}

@media(max-width: 750px) {
    .homepageGraphContainer {
        grid-template-columns: 150px auto;
    }

    /* .homepageGraphsWrapper {
        padding: 50px 10px;
    } */

    .homepageGraphValue {
        font-size: 32px;
    }

    .homepageGraphSubvalue {
        font-size: 16px;
    }
}

@media(max-width: 700px) {
    .homepageGraphsWrapper {
        padding: 25px 15px;
    }
}

.createTestContainer {
    background-color: var(--secondary-background-color);
    /* height: 100%; */
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    padding: 20px;
    z-index: 2;
    position: sticky;
    top: 0;
    left: 0;
}

.homepageTitleIcon {

}

.homepageTitleText {

}

/* Begin Create Test Section */

.createTestSectionContainer {
    /* padding-bottom needs to be set relative to the height of the homepage footer. Add resize event listener, setting
    the bottom padding from there. */
    padding-bottom: 20px;
}

.createTestSectionHeader {
    font-size: 22px;
    margin-bottom: 0px;
    /* font-weight: bold; */
}

.createTestSectionSelectAll {
    cursor: pointer;
    color: var(--primary-color);
    /* margin-bottom: 10px; */
    margin-top: 5px;
    margin-bottom: 5px;
    width: fit-content;
    text-decoration: underline;
}

.createTestSectionSubtext {
    font-size: 16px;
    margin: 0px;
    opacity: 0.8;
}

.createTestSectionContentSpacer {
    height: 10px;
}

.createTestSectionOptionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media(max-width: 1100px) {
    .createTestSectionOptionsContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 850px) {
    .createTestSectionOptionsContainer {
        grid-template-columns: 1fr;
    }
}

.createTestSectionOption {
    border-radius: 5px;
    border: 1px solid var(--unselected-border-color);
    background-color: rgba(255, 255, 255, 1);
    display: grid;
    grid-template-columns: 40px auto;
    padding: 0px;
    overflow: hidden;
    transition-duration: 150ms;
    color: var(--text-color);
}

.createTestSectionSimpleOption {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    justify-items: center;
    grid-template-rows: 1fr;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--unselected-border-color);
    background-color: rgba(0, 0, 0, 0);
    transition-duration: 150ms;
    color: var(--text-color);
}

.createTestSectionOption:hover, .createTestSectionSimpleOption:hover {
    background-color: rgba(250, 250, 250, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25); */
    transform: scale(1.015);
    /* transform: scale(0.995); */
}

.selectedOption {
    background-color: var(--tertiary-color) !important;
    border-color: var(--primary-color);
    transition-duration: 150ms;
    /* color: white; */
}

.createTestButtonCheckboxWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    border-right: 1px solid var(--unselected-border-color);
    background-color: rgba(0, 0, 0, 0);
    transition-duration: 150ms;
}

.checkboxWrapperChecked {
    background-color: var(--primary-color);
}

.createTestSectionOptionCheckboxContainer {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid var(--unselected-border-color);
    background-color: var(--secondary-background-color);
    display: grid;
}

.simpleCheckbox {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.createTestButtonCheckIcon {
    color: var(--primary-color);
    font-size: 14px;
    align-self: center;
    justify-self: center;
}

.simpleCheckIcon {
    color: white;
}

.createTestButtonContentWrapper {
    padding: 15px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    grid-template-rows: auto auto;
    align-self: center;
    /* background-color: var(--secondary-background-color); */
}

.createTestButtonIcon {
    font-size: 26px;
    margin-bottom: 6px;
    color: var(--text-color)
}

.createTestButtonName {
    font-size: 20px;
    align-self: center;
    margin: 0px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 6px;
}

.createTestButtonDescription {
    font-size: 14px;
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0px;
}

.createTestSectionIcon {

}

.createTestInput {
    border: 1px solid var(--unselected-border-color);
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    color: var(--text-color);
    height: 50px;
    font-size: 16px;
    background-color: var(--secondary-background-color);
    cursor: text;
}

/* End Create Test Section */

.homepageFooterContainer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    padding-left: calc(100% - 500px);
    /* padding-right: calc(50% - 250px); */
    justify-items: end;
    align-items: center;
    background-color: var(--secondary-background-color);
    border-top: 1px solid var(--unselected-border-color);
    column-gap: 10px;
    z-index: 3;
}

@media(max-width: 810px) {
    .homepageFooterContainer {
        padding-left: 10px;
    }
}

.homepageFooterButton {
    min-height: 40px;
    height: 100%;
    max-width: 250px; 
    width: 100%;
}

.homepageFooterSpinnerWrapper {
    width: 100%;
    max-width: 250px;
    grid-column: 2;
    display: grid;
    align-items: center;
    justify-items: center;
}

.homepageFooterSpinner {

}

/* End Homepage */
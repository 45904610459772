

:root {
    --dark-blue: #004976;
}

.calculator-container {
    height: fit-content;
    border-radius: 4px;
    grid-column: 2;
    grid-row: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    transition-duration: 500ms;
    background-color: var(--labs-value);
}

.calculator-wrapper {
    border: 2px solid var(--dark-blue);
    border-radius: 4px;
}

.calculator-screen-container {
    width: calc(100% - 20px);
    height: 40px;
    margin: 10px;
    margin-bottom: 0px;
    border: 1px solid #404040;
    border-radius: 4px;
    display: grid;
    align-items: center;
    justify-items: end;
    margin-bottom: 10px;
    background-color: white;
    position: relative;
}

.calculator-screen-text {
    font-size: 26px;
    color: #404040;
    margin: 0px;
    margin-right: 5px;
}

.calculator-memory-indicator {
    position: absolute;
    top: 0px;
    left: 5px;
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
}

.calculator-buttons-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 5px;
    margin: 10px;
}

.calculator-button {
    border-radius: 3px;
    border: 1px solid #404040;
    font-size: 16px;
    color: #181818;
    background: linear-gradient(#EEE 43%, #BBB);
    cursor: pointer;
    font-weight: bold;
}

.calculator-button:hover {
    background: linear-gradient(#BBB 43%, #EEE);
}

.calculator-purple {
    background: linear-gradient(#D4C9E8, #C286D6);
}

.calculator-purple:hover {
    background: linear-gradient(#C286D6, #D4C9E8);
}

.calculator-yellow {
    background: linear-gradient(#F7DB7F, #D8C26D);
}

.calculator-yellow:hover {
    background: linear-gradient(#D8C26D, #F7DB7F);
}

.calculator-blue {
    background: linear-gradient(#A7B7F5, #7999F8);
}

.calculator-blue:hover {
    background: linear-gradient(#7999F8, #A7B7F5);
}

.calculator-bottom-wrapper {
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: 37.5% calc(25% - 10px) 37.5%;
    column-gap: 5px;
}
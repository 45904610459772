

.viewCompletionsPopupPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
}

.viewCompletionsPopupBackdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.viewCompletionsPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95% - 30px);
    max-width: 400px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    z-index: 2;
    padding: 15px;
}

.viewCompletionsPopupTitle {
    margin: 0px;
    margin-bottom: 5px;
}

.viewCompletionsPopupSubtitle {
    opacity: 0.7;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 15px;
}

.viewCompletionsPopupOptionsContainer {
    background-color: #e5e5e5;
    /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25); */
    /* border: 1px solid #cccccc; */
    padding: 10px;
    border-radius: 3px;
    max-height: 200px;
    overflow-y: auto;
}

.viewCompletionsPopupOption {
    background-color: white;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    border-radius: 3px;
    /* border: 1px solid #cccccc; */
    overflow: hidden;
    margin-bottom: 10px;
}

.viewCompletionsPopupOptionSelected {
    background-color: #0B335D;
    filter: brightness(1);
    color: white;
}

.viewCompletionsPopupOption:nth-last-child(1) {
    margin-bottom: 0px;
}

.viewCompletionsPopupOptionGradeContainer {
    padding: 15px 0px;
    /* filter: brightness(0.8); */
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    /* background-color: var(--new-secondary-background-color); */
    /* opacity: 0.9; */
}

.viewCompletionsPopupOptionGrade {
    margin: 0px;
    font-weight: bold;
    color: white;
}

.viewCompletionsPopupOptionDate {
    margin: 0px;
    font-weight: 500;
}

.viewCompletionsPopupButtonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    margin-top: 15px;
}

.viewCompletionsPopupButton {
    height: 40px;
}

@media(max-width: 750px) {
    .viewCompletionsPopupTitle {
        font-size: 22px;
    }

    .viewCompletionsPopupSubtitle {
        font-size: 14px;
    }

    .viewCompletionsPopupOptionGradeContainer {
        padding: 10px 0px;
    }
}

.popupCloseButton {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
}

.popupCloseButton::before, .popupCloseButton::after {
    position: absolute;
    content: "";
    height: 20px;
    width: 2px;
    border-radius: 2px;
    background-color: #404040;
}

.popupCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.popupCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.popupCloseButton:hover {
    background-color: rgba(0, 0, 0, 0);
}


:root {
    --background-color: linear-gradient(#fff, #d7ddec);
    --labs-text-color: #000;
    --labs-color: #D7DCED;
    --input-color: #FFFFFF;
}

mark {
    background-color: yellow !important;
    padding: 0px !important;
}

.labsWrapper {
    overflow: hidden;
    height: calc(100vh - 120px);
    right: 0;
    top: 0;
    display: inline-grid;
    grid-template-rows: auto 1fr;
    vertical-align: top;
    padding-top: 5px;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    grid-column: 2;
    grid-row: 1;
}

.labsHeaderWrapper {
    height: 20px;
    width: 100%;
    display: inline-grid;
    margin-bottom: 5px;
    grid-template-columns: 1fr auto;
}

.labsSearch {
    cursor: text;
    height: 85%;
    font-size: 12px;
    max-width: 150px;
}

.labsHeaderCheckboxWrapper {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    margin-right: 5px;
}

.labsHeaderCheckboxWrapper > label {
    font-size: 12px;
    color: var(--labs-text-color);
}

.labsBodyWrapper {
    height: calc(100% - 30px);
    display: inline-grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    width: 100%;
    overflow-y: auto;
}

.labsTabsWrapper {
    display: inline-block;
    width: 100%;
    position: relative;
    top: 2px;
    z-index: 2;
}

.labsTab {
    border-radius: 3px 3px 0px 0px;
    margin: 0px 0px 1px 1em;
    border: 1px solid #AAB;
    padding: .25rem .5rem;
    font-size: 12px;
    background: var(--labs-color);
    color: var(--labs-text-color);
}

.labsTabSelected {
    background-color: var(--labs-color);
    border-bottom-color: var(--labs-color);
    font-weight: bold;
}

@media(max-width: 900px) {
    .labsTab {
        margin: 0px 0px 1px 0px;
    }
}

.labsBody {
    background-color: var(--labs-color);
    border: 1px solid #AAB;
    height: 100%;
    overflow-y: auto;
}

.labsValueContainer {
    display: grid;
    column-gap: 10px;
    border-bottom: 1px solid #AAB;
}

.labsHeader {
    font-weight: 900;
    font-size: 12px;
    margin: 0px;
    padding-left: 5px;
    color: var(--labs-text-color);
}

.labsName {
    font-size: 12px;
    margin: 0px;
    padding-left: 10px;
    font-weight: 500;
    color: var(--labs-text-color);
}

.labsNameExtraPadding {
    padding-left: 18px;
}

.labsValue {
    font-size: 12px;
    margin: 0px;
    font-weight: 500;
    color: var(--labs-text-color);
    white-space: pre-wrap;
}

.labsValue > span {
    white-space: pre-wrap;
}